import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import BlockContent from "@sanity/block-content-to-react"
import { Headers, Main, ParsWrapper } from "../components/typography"
import StackGrid from "react-stack-grid"
import sizeMe from "react-sizeme"
import { FaFileDownload } from "react-icons/fa"

const Resource = styled.div`
  h1 {
    color: blue;
    font-size: 28px;
    @media (min-width: 768px) {
      font-size: 38px;
    }
  }
  .pdf {
    font-size: 1.5rem;
    text-decoration: none;
    background: #0000ff66;
    color: #fff;
    border-radius: 20px;
    margin-bottom: 15px;
    text-align: left;
    span {
      padding-left: 20px;
    }
    @media (min-width: 768px) {
      padding: 10px;
      font-size: 2rem;
    }
    display: inline-block;
    width: auto;
    padding: 5px;
    &:hover {
      background: blue;
      cursor: pointer;
    }
  }
  .card {
    background: lightgrey;
    padding: 5px 25px 25px 25px;
    border-radius: 20px;
    p {
      line-height: 2rem;
    }
    a {
      word-break: break-all;
    }
    h3 {
      padding: 0;
      margin: 0;
      font-size: 22px;
      line-height: 28px;
      @media (min-width: 768px) {
        font-size: 32px;
        line-height: 38px;
      }
    }
    .card-single {
      border-top: 2px solid #ffffff;
      margin: 30px 0;
      @media (min-width: 768px) {
        padding: 25px;
      }
      //box-shadow: 1px 1px 10px #ffffff;
    }
  }
`

const Download = styled.div`
  // @media (min-width: 768px) {
  //   display: grid;
  //   grid-row-gap: 25px;
  //   grid-template-columns: repeat(2, 250px);
  // }
`

const Resources = ({ data, size }) => {
  const { width } = size

  let titleArray = []
  let pdfArray = []
  let pdfTitleArray = []

  data.allSanityResources.edges.map(({ node: resource }) => {
    titleArray = resource.sectionDesc
  })

  const serializers = {
    types: {
      code: props => (
        <pre data-language={props.node.language}>
          <code>{props.node.code}</code>
        </pre>
      ),
    },
  }
  return (
    <Layout>
      <Main>
        <Resource>
          <StackGrid
            columnWidth={width <= 768 ? "100%" : "50%"}
            gutterWidth={50}
            gutterHeight={50}
          >
            {data.allSanityResources.edges.map(({ node: resource }) => {
              return (
                <div className="card">
                  <Headers className="title">{resource.title}</Headers>
                  {resource._rawSectionDesc.map((block, i) => {
                    return (
                      <>
                        <div className="card-single">
                          {/* {titleArray[i] && ( */}
                          <>
                            <ParsWrapper>
                              <h3>{block.title}</h3>
                              <BlockContent
                                blocks={block.content}
                                serializers={serializers}
                              />
                            </ParsWrapper>
                            {block.pdfUpload && (
                              <a
                                className="pdf"
                                href={
                                  block.pdfUpload.asset &&
                                  block.pdfUpload.asset.url
                                }
                              >
                                {block.pdfTitle}
                                <span>
                                  <FaFileDownload color="#fff" />
                                </span>
                              </a>
                            )}
                          </>
                        </div>
                      </>
                    )
                  })}
                </div>
              )
            })}
          </StackGrid>
        </Resource>
      </Main>
    </Layout>
  )
}

export default sizeMe()(Resources)

export const query = graphql`
  query ResourcesQuery {
    allSanityResources {
      edges {
        node {
          title
          _rawSectionDesc(resolveReferences: { maxDepth: 10 })
          sectionDesc {
            title
            pdfTitle
          }
        }
      }
    }
  }
`
